import React from "react"
import Layout from "../../components/global/Layout"

const RangeringAfHoteller = () => {
  return (
    <Layout
      metaTitle="Sådan rangeres hotellerne"
      metaDescription="Læs mere om vores rangering af hoteller, og hvordan vi gør."
      slug="/information/rangering-af-hoteller/"
    >
      <div className="container my-xl">
        <section className="text-content">
          <h1>Rangering af ophold/hoteller</h1>
          <p>
            Hos Opholdsguide.dk ønsker vi en stor gennemsigtighed på
            hjemmesiden. Derfor har vi lavet denne side, hvor vi vil beskrive
            vores tanker omkring placering af ophold/hoteller på vores
            hjemmeside, samt hvordan vi bruger badges.
          </p>

          <h2>Hvordan rangerer vi ophold/hoteller</h2>
          <p>
            Vores guides er lavet som lister. Vi rangerer ophold/hoteller ved at
            foretage en vurdering af de forskellige hoteller. Her kigger vi
            blandt andet på:
          </p>
          <ul>
            <li>Beliggenhed</li>
            <li>
              Andre ting i relation til typen af ophold. Det kunne for eksempel
              være hotellets spafaciliteter på en side om spaophold.
            </li>
            <li>Tidligere kunders oplevelse (kan ses på bookingportalen)</li>
          </ul>

          <p>
            Ofte hænger tingene sammen. Kan et hotel/ophold levere en god
            kvalitet til en fornuftig pris, vil det ofte resultere i en øget
            popularitet. Det er vores oplevelse.
          </p>

          <h2>Brug af badges</h2>

          <p>
            Vi gør i vores guides brug af forskellige badges, for at du og
            resten af vores besøgende lettere kan overskue vores guides.
            Nedenfor vil vi præcisere, hvornår de forskellige badges tildeles.
          </p>

          <h4>Personlig favorit</h4>
          <p>
            Badget for personlig favorit gives til det ophold/hotel forfatteren
            af den givne guide ville vælge. Her er det udelukkende vores
            personlige holdning, og en annoncør vil aldrig kunne betale os noget
            med henblik på at blive tildelt badget. Vi ønsker, at badget skal
            placeres ud fra den viden, vi har om de forskellige ophold/hoteller.
          </p>
          <h4>Godt til prisen</h4>
          <p>
            Dette badge tildeles hvis vi vurderer, at et ophold/hotel er ekstra
            godt til prisen, og hvis du får meget for pengene – og til en god
            pris. Det fremgår ikke nødvendigvis i alle guides, hvis mange af
            opholdene/hotellerne ligger i det samme prisleje og kan tilbyde
            nogenlunde det samme. Badget bruges primært til hoteller i den
            lavere ende af prisskalaen, men hvor du kan forvente en god
            oplevelse, der måske er på niveau med de hoteller/ophold, der har en
            højere pris. Badget skal hjælpe de besøgende, der har et begrænset
            budget og dem, der gerne vil have noget for pengene.
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default RangeringAfHoteller
